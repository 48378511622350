import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["duprClubIdField"]

  connect() {
    console.log('DuprInput controller connected');
  }

  toggleDuprClubId(event) {
    const isChecked = event.target.value === "true"
    this.duprClubIdFieldTarget.querySelector('input').value = '';
    this.duprClubIdFieldTarget.style.display = isChecked ? "block" : "none"
  }
}
