import { Controller } from "@hotwired/stimulus"
import Hls from "hls.js"

export default class extends Controller {
    static targets = ["video", "loader", "container"]
    static values = { url: String, matchStatus: String }

    connect() {
        if (Hls.isSupported()) {
            this.hls = new Hls({
                maxBufferSize: 15 * 1000 * 1000, // Buffer size for 4-second segments
                maxBufferLength: 16, // 4 segments (16 seconds)
                maxMaxBufferLength: 25, // About 6 segments
                liveSyncDurationCount: 4, // 4 * 4 = 16 seconds
                liveMaxLatencyDurationCount: 7, // 7 * 4 = 28 seconds max latency
                highBufferWatchdogPeriod: 16, // 4 * 4 seconds
                nudgeMaxRetry: 10,
                enableWorker: true,
                capLevelToPlayerSize: true,
                defaultAudioCodec: undefined,
                liveDurationInfinity: this.matchStatusValue === "ongoing",
                lowLatencyMode: this.matchStatusValue === "ongoing",
            });
            this.hls.loadSource(this.urlValue);
            this.hls.attachMedia(this.videoTarget);
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                this.videoTarget.setAttribute('width', '1280');
                this.videoTarget.setAttribute('height', '720');
                if (this.matchStatusValue === "finished") {
                    this.videoTarget.controls = true;
                    this.hls.startLoad(-1); // Start from live edge for finished matches
                } else if (this.matchStatusValue === "ongoing") {
                    this.videoTarget.controls = true;
                }
                this.videoTarget.play();
                this.loaderTarget.classList.add('hidden');
                this.containerTarget.classList.remove('hidden');
            });
        } else if (this.videoTarget.canPlayType('application/vnd.apple.mpegurl')) {
            this.videoTarget.src = this.urlValue;
            this.videoTarget.setAttribute('width', '1280');
            this.videoTarget.setAttribute('height', '720');
            this.videoTarget.setAttribute('autoplay', '');
            this.videoTarget.muted = true;
            this.videoTarget.addEventListener('loadedmetadata', () => {
                if (this.matchStatusValue === "finished") {
                    this.videoTarget.controls = true;
                    this.videoTarget.currentTime = 0;
                } else if (this.matchStatusValue === "ongoing") {
                    this.videoTarget.controls = true;
                }
                this.loaderTarget.classList.add('hidden');
                this.containerTarget.classList.remove('hidden');
            });
        }
    }

    disconnect() {
        if (this.hls) {
            this.hls.destroy();
        }
        if (this.videoTarget) {
            this.videoTarget.pause();
            this.videoTarget.removeAttribute('src');
            this.videoTarget.load();
        }
    }
}
