import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["duplicateId", "nameSelect", "phoneSelect", "avatarSelect"]

  connect() {
    console.log("Merge Users controller connected")
    this.addHiddenInputListener()
  }

  addHiddenInputListener() {
    const hiddenInput = this.duplicateIdTarget.querySelector('#duplicate_id-hw-hidden-field')
    if (hiddenInput) {
      // Create a MutationObserver instance
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "attributes" && mutation.attributeName === "value") {
            this.handleHiddenInputChange(hiddenInput)
          }
        })
      })

      // Configure the observer to watch for changes to the value attribute
      observer.observe(hiddenInput, { attributes: true, attributeFilter: ['value'] })

      // Also listen for the change event as a fallback
      hiddenInput.addEventListener('change', () => this.handleHiddenInputChange(hiddenInput))
    }
  }

  handleHiddenInputChange(hiddenInput) {
    console.log("Hidden input value changed:", hiddenInput.value)
    this.updateOptions()
  }

  async updateOptions() {
    const hiddenInput = this.duplicateIdTarget.querySelector('#duplicate_id-hw-hidden-field')
    const selectedUserId = hiddenInput ? hiddenInput.value : null
    if (!selectedUserId) return;

    try {
      const response = await fetch(`/admin/users/${selectedUserId}.json`);
      const data = await response.json();

      // Update the duplicate user info
      const duplicateUserInfo = this.element.querySelector('#duplicate-user-info');
      if (duplicateUserInfo) {
        duplicateUserInfo.classList.remove('hidden');

        const avatarImg = duplicateUserInfo.querySelector('#duplicate-user-avatar');
        if (avatarImg) avatarImg.src = data.avatar_url;

        const phoneSpan = duplicateUserInfo.querySelector('#duplicate-user-phone');
        if (phoneSpan) phoneSpan.textContent = data.phone_number;

        const nameSpan = duplicateUserInfo.querySelector('#duplicate-user-name');
        if (nameSpan) nameSpan.textContent = data.full_name;
      }

      // Update the select options
      this.updateSelectOption(this.phoneSelectTarget, data.phone_number, data.full_name);
      this.updateSelectOption(this.nameSelectTarget, data.full_name, null);
      this.updateSelectOption(this.avatarSelectTarget, data.full_name + "'s Avatar", null);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }

  updateSelectOption(selectElement, value, label) {
    if (selectElement) {
      const option = selectElement.querySelector('option[value="duplicate_user"]');
      if (option) {
        option.value = 'duplicate_user';
        option.textContent = label && label.trim() !== '' ? `${label}: ${value}` : value;
      } else {
        const newOption = new Option(label ? `${label}: ${value}` : value, 'duplicate_user');
        selectElement.add(newOption);
      }
    }
  }

  updatePhoneSelectOptions(phoneNumber) {
    this.removeDuplicateOption()
    const newOption = new Option(`Duplicate User: ${phoneNumber}`, 'duplicate_user')
    this.phoneSelectTarget.add(newOption)
  }

  removeDuplicateOption() {
    const existingDuplicateOption = this.phoneSelectTarget.querySelector('option[value="duplicate_user"]')
    if (existingDuplicateOption) {
      existingDuplicateOption.remove()
    }
  }
}
